/*global CustomFunctions */
import { ParameterSchema, RevisionSchema } from "@ddb/parameter-service";
import { at, first, toMap } from "../../shared/asyncIterator";
import { Batcher } from "../../shared/batch";
import { parameterService, unpaginate, validateEnvironment } from "../../shared/ddb";
import { DdbEnvironment } from "../../shared/types";
import { isGuid } from "../../shared/helpers";

class ParametersBatcher extends Batcher<ParameterSchema> {
  async _getResults(environment: DdbEnvironment, keys: string[]): Promise<Map<string, ParameterSchema>> {
    const client = parameterService(environment);
    return await toMap(
      unpaginate(
        (after) => client.getAllParameters({ after, parameterId: keys }),
        (data) => data.data.parameters,
        (data) => data.data.paging?.cursors?.after
      ),
      (parameter) => parameter.id,
      (parameter) => parameter
    );
  }
}

const parametersBatcher = new ParametersBatcher(10, "parameters");

/**
 * Gets DDB parameter value.
 * @customfunction PARAMETER.VALUE
 * @param environment The DDB environment to use.
 * @param parameterId The parameter ID.
 * @param [revision] The parameter revision. If omitted, version = "latest".
 * @returns The parameter value.
 * @streaming
 */
export function parameterValue(
  environment: string,
  parameterId: string,
  revision: string | number | null,
  invocation: CustomFunctions.StreamingInvocation<any>
): void {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(parameterId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Parameter ID must be a GUID.");
  }
  if (
    (revision !== null && typeof revision !== "number" && revision !== "latest" && !isGuid(revision)) ||
    revision === 0
  ) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid revision.");
  }

  /* return latest revision */
  if (revision === null || revision === "latest") {
    parametersBatcher.enqueue(
      environment,
      parameterId,
      invocation,
      (parameter) => parameter.selected_entry?.values?.at(0)?.value ?? ""
    );
  } else {
    parametersBatcher.enqueue(environment, parameterId, invocation, async (parameter) => {
      const entryRevision = await getParameterRevision(environment, parameter, revision);
      return entryRevision?.values.at(0)?.value || "";
    });
  }
}

async function getParameterRevision(
  environment: DdbEnvironment,
  parameter: ParameterSchema,
  revision: string | number
): Promise<RevisionSchema> {
  const client = parameterService(environment);
  const entryId = (parameter.selected_entry?.id || "") as Object;
  const revisions = unpaginate(
    (after) => client.getEntryRevisions({ after, entryId, order: "asc" }),
    (data) => data.data.revisions,
    (data) => data.data.paging?.cursors?.after
  );
  const entryRevision =
    typeof revision === "number"
      ? await at(revisions, revision - 1)
      : await first(revisions, (rev) => rev.id === revision);

  /* throw error if revision not found */
  if (!entryRevision) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid version.");
  }
  return entryRevision;
}

/**
 * Gets DDB parameter name.
 * @customfunction PARAMETER.PARAMETER_TYPE_NAME
 * @param environment The DDB environment to use.
 * @param parameterId The parameter ID.
 * @returns The parameter name.
 * @streaming
 */
export function parameterparameterTypeName(
  environment: string,
  parameterId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!validateEnvironment(environment)) {
    throw new Error("Invalid environment.");
  }
  if (!isGuid(parameterId)) {
    throw new Error("Parameter ID must be a GUID.");
  }
  parametersBatcher.enqueue(environment, parameterId, invocation, (parameter) => parameter.parameter_type.name ?? "");
}

/**
 * Gets DDB parameter source ID.
 * @customfunction PARAMETER.SOURCE_ID
 * @param environment The DDB environment to use.
 * @param parameterId The parameter ID.
 * @returns The parameter source ID.
 * @streaming
 */
export function parameterSourceId(
  environment: string,
  parameterId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(parameterId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Parameter ID must be a GUID.");
  }
  parametersBatcher.enqueue(
    environment,
    parameterId,
    invocation,
    (parameter) => parameter.selected_entry?.source?.id ?? ""
  );
}

/**
 * Gets DDB parameter status.
 * @customfunction PARAMETER.STATUS
 * @param environment The DDB environment to use.
 * @param parameterId The parameter ID.
 * @returns The parameter status.
 * @streaming
 */
export function parameterStatus(
  environment: string,
  parameterId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(parameterId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Parameter ID must be a GUID.");
  }
  parametersBatcher.enqueue(
    environment,
    parameterId,
    invocation,
    (parameter) => parameter.selected_entry?.status ?? "unanswered"
  );
}

/**
 * Gets DDB parameter unit symbol.
 * @customfunction PARAMETER.UNIT_SYMBOL
 * @param environment The DDB environment to use.
 * @param parameterId The parameter ID.
 * @returns The parameter unit symbol.
 * @streaming
 */
export function parameterUnitSymbol(
  environment: string,
  parameterId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(parameterId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Parameter ID must be a GUID.");
  }
  parametersBatcher.enqueue(
    environment,
    parameterId,
    invocation,
    (parameter) => parameter.selected_entry?.values?.at(0)?.unit?.symbol ?? ""
  );
}

CustomFunctions.associate("PARAMETER.VALUE", parameterValue);
CustomFunctions.associate("PARAMETER.PARAMETER_TYPE_NAME", parameterparameterTypeName);
CustomFunctions.associate("PARAMETER.SOURCE_ID", parameterSourceId);
CustomFunctions.associate("PARAMETER.STATUS", parameterStatus);
CustomFunctions.associate("PARAMETER.UNIT_SYMBOL", parameterUnitSymbol);