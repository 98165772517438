/*global CustomFunctions */
import { getBatchers } from "../commands/commands";
import { Cache } from "./cache";
import { DdbEnvironment } from "./types";
import { chunk, union } from "./helpers";

interface Entry<T> {
  key: string;
  resolve: (result: T) => Promise<void>;
  reject: (error: CustomFunctions.Error) => void;
  resolved: boolean;
}

export interface RefreshableBatcher {
  refresh(): Promise<void>;
}

type Result<T> = { type: "success"; value: T } | { type: "error"; error: any };

export abstract class Batcher<T> implements RefreshableBatcher {
  #entries: Record<DdbEnvironment, Entry<T>[]>;
  batchSize: number;
  #timeouts: { [key in DdbEnvironment]?: ReturnType<typeof setTimeout> };
  #cache: Cache<T>;

  constructor(batchSize: number, cache: string | Cache<T>) {
    this.batchSize = batchSize;
    this.#entries = {
      develop: [],
      sandbox: [],
      production: [],
    };
    this.#timeouts = {};
    getBatchers().push(this);
    this.#cache = typeof cache === "string" ? new Cache(cache) : cache;
  }

  enqueue<V>(
    environment: DdbEnvironment,
    key: string,
    invocation: CustomFunctions.StreamingInvocation<V>,
    valueSelector: (result: T) => V | Promise<V>
  ): void {
    invocation.onCanceled = () => this.cancel(environment, key);
    const entries = this.#entries[environment];

    const resolve = async (result: T) => invocation.setResult(await valueSelector(result));
    const reject = (error: CustomFunctions.Error) => invocation.setResult(error);

    entries.push({ key, resolve, reject, resolved: false });
    const interval = this.#timeouts[environment];
    if (entries.length >= this.batchSize) {
      clearTimeout(interval);
      delete this.#timeouts[environment];
      this.#processBatch(environment, false);
    } else if (interval === undefined) {
      this.#timeouts[environment] = setTimeout(() => {
        this.#processBatch(environment, false);
        delete this.#timeouts[environment];
      }, 100);
    }
  }

  async #processBatch(environment: DdbEnvironment, sendAll: boolean) {
    const entries = this.#entries[environment].filter((entry) => sendAll || !entry.resolved);
    const toGet: Entry<T>[] = [];
    for (const entry of entries) {
      const cached = this.#cache.get(environment, entry.key);
      if (cached !== undefined) {
        entry.resolve(cached);
      } else {
        toGet.push(entry);
      }
    }

    try {
      const resultMaps = await Promise.all(
        chunk([...new Set(toGet.map((entry) => entry.key))], this.batchSize).map((keys) =>
          this.#sendRequest(environment, keys)
        )
      );

      const results = union(resultMaps);
      await Promise.all(toGet.map((entry) => this.#processEntry(environment, results, entry)));
    } catch (error) {
      for (const entry of toGet) {
        entry.reject(error);
      }
    }
    const toChange = await this.#cache.sync();
    for (const { environment, key, value } of toChange) {
      const entry = this.#entries[environment].find((entry) => entry.key === key);
      if (entry) {
        await entry.resolve(value);
      }
    }
  }

  async #processEntry(environment: DdbEnvironment, results: Map<string, Result<T>>, entry: Entry<T>) {
    const result = results.get(entry.key);
    if (result === undefined) {
      entry.reject(new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, `No result for key ${entry.key}`));
    } else if (result.type === "error") {
      entry.reject(new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, String(result.error)));
    } else {
      await entry.resolve(result.value);
      this.#cache.set(environment, entry.key, result.value);
      entry.resolved = true;
    }
  }

  async #sendRequest(environment: DdbEnvironment, keys: string[]): Promise<Map<string, Result<T>>> {
    try {
      const results = await this._getResults(environment, keys);
      const wrapped = new Map([...results.entries()].map(([key, value]) => [key, { type: "success", value }] as const));
      return wrapped;
    } catch (error) {
      const half = Math.ceil(keys.length / 2);
      if (half <= 1) {
        const result = new Map<string, Result<T>>();
        result.set(keys[0], { type: "error", error });
        return result;
      }
      const [left, right] = [keys.slice(0, half), keys.slice(half)];
      const results = await Promise.all([this.#sendRequest(environment, left), this.#sendRequest(environment, right)]);
      return union(results);
    }
  }

  protected abstract _getResults(environment: DdbEnvironment, keys: string[]): Promise<Map<string, T>>;

  cancel(environment: DdbEnvironment, key: string) {
    this.#cache.delete(environment, key);
    const entries = this.#entries[environment];
    const index = entries.findIndex((entry) => entry.key === key);
    if (index !== -1) {
      const entry = entries[index];
      entry.reject(new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Cancelled"));
      entries.splice(index, 1);
    }
  }

  async refresh(): Promise<void> {
    this.#cache.clear();
    for (const environment of Object.keys(this.#entries) as DdbEnvironment[]) {
      const interval = this.#timeouts[environment];
      if (interval !== undefined) {
        clearTimeout(interval);
        delete this.#timeouts[environment];
      }
      if (this.#entries[environment].length > 0) {
        await this.#processBatch(environment, true);
      }
    }
    await this.#cache.sync();
  }
}
