/*global CustomFunctions*/

import { ParameterTypeSchema } from "@ddb/taxonomy-service";
import { Batcher } from "../../shared/batch";
import { taxonomyService, unpaginate } from "../../shared/ddb";
import { toMap } from "../../shared/asyncIterator";
import { isGuid, formulaErrorHandling } from "../../shared/helpers";
import { FormulaErrorFormat } from "../../shared/types";

class ParameterTypesBatcher extends Batcher<ParameterTypeSchema> {
  async _getResults(keys: string[]): Promise<Map<string, ParameterTypeSchema>> {
    const client = taxonomyService();
    return await toMap(
      unpaginate(
        (after) => client.getAllParameterTypes({ after, parameterTypeId: keys, showLocal: true }),
        (data) => data.data.parameter_types,
        (data) => data.data.paging?.cursors?.after
      ),
      (parameter) => parameter.id
    );
  }
}

const parameterTypesBatcher = new ParameterTypesBatcher(10, "parameterTypes");

/**
 * Gets DDB parameter type name.
 * @customfunction PARAMETER_TYPE.NAME
 * @param parameterTypeId The parameter type ID.
 * @returns The parameter type name.
 * @streaming
 */
export function parameterTypeName(
  parameterTypeId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!isGuid(parameterTypeId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_TYPE_ID));
    return;
  }
  parameterTypesBatcher.enqueue(parameterTypeId, invocation, (parameterType) => parameterType.name);
}

CustomFunctions.associate("PARAMETER_TYPE.NAME", parameterTypeName);