/*global CustomFunctions */
import { AssetSchema } from "@ddb/parameter-service";
import { toMap } from "../../shared/asyncIterator";
import { Batcher } from "../../shared/batch";
import { parameterService, unpaginate } from "../../shared/ddb";
import { isGuid, formulaErrorHandling } from "../../shared/helpers";
import { FormulaErrorFormat } from "../../shared/types";

class AssetBatcher extends Batcher<AssetSchema> {
  async _getResults(keys: string[]): Promise<Map<string, AssetSchema>> {
    const client = parameterService();
    return await toMap(
      unpaginate(
        (after) => client.getAssets({ assetId: keys, after }),
        (data) => data.data.assets,
        (data) => data.data.paging?.cursors?.after
      ),
      (asset) => asset.id
    );
  }
}

const assetBatcher = new AssetBatcher(10, "assets");

/**
 * Gets DDB asset name.
 * @customfunction ASSET.NAME
 * @param assetId The asset ID.
 * @returns The asset name.
 * @streaming
 */
export function assetName(assetId: string, invocation: CustomFunctions.StreamingInvocation<string>): void {
  if (!isGuid(assetId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.ASSET_ID));
    return;
  }
  assetBatcher.enqueue(assetId, invocation, (asset) => asset.name);
}

/**
 * Gets DDB asset parent.
 * @customfunction ASSET.PARENT_ID
 * @param assetId The asset ID.
 * @returns The asset name.
 * @streaming
 */
export function parentAssetId(assetId: string, invocation: CustomFunctions.StreamingInvocation<string>): void {
  if (!isGuid(assetId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.ASSET_ID));
    return;
  }
  assetBatcher.enqueue(assetId, invocation, (asset) => asset.parent ?? "");
}

/**
 * Gets DDB asset children.
 * @customfunction ASSET.CHILD_IDS
 * @param assetId The asset ID.
 * @returns The asset child IDs.
 * @streaming
 */
export function childIds(assetId: string, invocation: CustomFunctions.StreamingInvocation<string[][]>): void {
  if (!isGuid(assetId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.ASSET_ID));
    return;
  }
  assetBatcher.enqueue(assetId, invocation, (asset) => asset.children?.map((child) => [child]) ?? []);
}

/**
 * Gets DDB asset type ID.
 * @customfunction ASSET.ASSET_TYPE_ID
 * @param assetId The asset ID.
 * @returns The asset type ID.
 * @streaming
 */
export function assetTypeId(assetId: string, invocation: CustomFunctions.StreamingInvocation<string>): void {
  if (!isGuid(assetId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.ASSET_ID));
    return;
  }
  assetBatcher.enqueue(assetId, invocation, (asset) => asset.asset_type.id);
}

/**
 * Gets DDB asset type name.
 * @customfunction ASSET.ASSET_TYPE_NAME
 * @param assetId The asset ID.
 * @returns The asset type name.
 * @streaming
 */
export function assetTypeName(assetId: string, invocation: CustomFunctions.StreamingInvocation<string>): void {
  if (!isGuid(assetId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.ASSET_ID));
    return;
  }
  assetBatcher.enqueue(assetId, invocation, (asset) => asset.asset_type.name);
}

CustomFunctions.associate("ASSET.NAME", assetName);
CustomFunctions.associate("ASSET.PARENT_ID", parentAssetId);
CustomFunctions.associate("ASSET.CHILD_IDS", childIds);
CustomFunctions.associate("ASSET.ASSET_TYPE_ID", assetTypeId);
CustomFunctions.associate("ASSET.ASSET_TYPE_NAME", assetTypeName);