/*global CustomFunctions */
import { ParameterSchema, RevisionSchema } from "@ddb/parameter-service";
import { first, toMap } from "../../shared/asyncIterator";
import { Batcher } from "../../shared/batch";
import { parameterService, unpaginate } from "../../shared/ddb";
import { isGuid, formulaErrorHandling } from "../../shared/helpers";
import { FormulaErrorFormat } from "../../shared/types";

class ParametersBatcher extends Batcher<ParameterSchema> {
  async _getResults(keys: string[]): Promise<Map<string, ParameterSchema>> {
    const client = parameterService();
    const [parameters, project_level_params] = await Promise.all([
      toMap(
        unpaginate(
          (after) => client.getAllParameters({ after, parameterId: keys, projectParameter: false }),
          (data) => data.data.parameters,
          (data) => data.data.paging?.cursors?.after
        ),
        (parameter) => parameter.id,
        (parameter) => parameter
      ),
      toMap(
        unpaginate(
          (after) => client.getAllParameters({ after, parameterId: keys, projectParameter: true }),
          (data) => data.data.parameters,
          (data) => data.data.paging?.cursors?.after
        ),
        (parameter) => parameter.id,
        (parameter) => parameter
      ),
    ]);
    return new Map([...parameters, ...project_level_params]);
  }
}

const parametersBatcher = new ParametersBatcher(10, "parameters");

/* TODO - move this into the batcher */
export async function getParameterRevision(
  parameter: ParameterSchema,
  revision: RevisionSchema["id"]
): Promise<RevisionSchema> {
  const parameterclient = parameterService();
  const entryRevision = await first(
    unpaginate(
      (after) =>
        parameterclient.getEntryRevisions({
          after,
          /* type cast to satisfy the incorrect type definition in ddb api */
          entryId: (parameter.selected_entry?.id || "") as Object,
          order: "asc",
        }),
      (data) => data.data.revisions,
      (data) => data.data.paging?.cursors?.after
    ),
    (rev) => rev.id === revision
  );

  if (!entryRevision) {
    throw formulaErrorHandling(FormulaErrorFormat.INVALID_REVISION);
  }
  return entryRevision;
}

/**
 * Gets DDB parameter value.
 * @customfunction PARAMETER.VALUE
 * @param parameterId The parameter ID.
 * @param [revisionId] The parameter revision ID. If omitted, the latest revision is returned.
 * @returns The parameter value at the specified revision.
 * @streaming
 */
export function parameterValue(
  parameterId: string,
  revisionId: string | null,
  invocation: CustomFunctions.StreamingInvocation<any>
): void {
  if (!isGuid(parameterId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_ID));
    return;
  }
  if (revisionId !== null && !isGuid(revisionId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.INVALID_REVISION));
    return;
  }

  /* return latest revision */
  if (revisionId === null) {
    parametersBatcher.enqueue(
      parameterId,
      invocation,
      (parameter) => parameter.selected_entry?.values?.at(0)?.value ?? ""
    );
  } else {
    parametersBatcher.enqueue(parameterId, invocation, async (parameter) => {
      const entryRevision = await getParameterRevision(parameter, revisionId);
      return entryRevision?.values.at(0)?.value || "";
    });
  }
}

/**
 * Gets DDB parameter name.
 * @customfunction PARAMETER.PARAMETER_TYPE_NAME
 * @param parameterId The parameter ID.
 * @returns The parameter name.
 * @streaming
 */
export function parameterparameterTypeName(
  parameterId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!isGuid(parameterId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_ID));
    return;
  }
  parametersBatcher.enqueue(parameterId, invocation, (parameter) => parameter.parameter_type.name ?? "");
}

/**
 * Gets DDB parameter source ID.
 * @customfunction PARAMETER.SOURCE_ID
 * @param parameterId The parameter ID.
 * @param [revisionId] The parameter revision ID. If omitted, the latest revision is returned.
 * @returns The parameter source ID.
 * @streaming
 */
export function parameterSourceId(
  parameterId: string,
  revisionId: string | null,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!isGuid(parameterId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_ID));
    return;
  }
  if (revisionId !== null && !isGuid(revisionId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.INVALID_REVISION));
    return;
  }

  /* return latest revision */
  if (revisionId === null) {
    parametersBatcher.enqueue(parameterId, invocation, (parameter) => parameter.selected_entry?.source?.id ?? "");
  } else {
    parametersBatcher.enqueue(parameterId, invocation, async (parameter) => {
      const entryRevision = await getParameterRevision(parameter, revisionId);
      return entryRevision?.source?.id || "";
    });
  }
}

/**
 * Gets DDB parameter status.
 * @customfunction PARAMETER.STATUS
 * @param parameterId The parameter ID.
 * @param [revisionId] The parameter revision ID. If omitted, the latest revision is returned.
 * @returns The parameter status.
 * @streaming
 */
export function parameterStatus(
  parameterId: string,
  revisionId: string | null,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!isGuid(parameterId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_ID));
    return;
  }
  if (revisionId !== null && !isGuid(revisionId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.INVALID_REVISION));
    return;
  }

  /* return latest revision */
  if (revisionId === null) {
    parametersBatcher.enqueue(parameterId, invocation, (parameter) => parameter.selected_entry?.status ?? "unanswered");
  } else {
    parametersBatcher.enqueue(parameterId, invocation, async (parameter) => {
      const entryRevision = await getParameterRevision(parameter, revisionId);
      return entryRevision?.status || "unanswered";
    });
  }
}

/**
 * Gets DDB parameter unit symbol.
 * @customfunction PARAMETER.UNIT_SYMBOL
 * @param parameterId The parameter ID.
 * @param [revisionId] The parameter revision ID. If omitted, the latest revision is returned.
 * @returns The parameter unit symbol.
 * @streaming
 */
export function parameterUnitSymbol(
  parameterId: string,
  revisionId: string | null,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!isGuid(parameterId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_ID));
    return;
  }
  if (revisionId !== null && !isGuid(revisionId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.INVALID_REVISION));
    return;
  }

  /* return latest revision */
  if (revisionId === null) {
    parametersBatcher.enqueue(
      parameterId,
      invocation,
      (parameter) => parameter.selected_entry?.values?.at(0)?.unit?.symbol ?? ""
    );
  } else {
    parametersBatcher.enqueue(parameterId, invocation, async (parameter) => {
      const entryRevision = await getParameterRevision(parameter, revisionId);
      return entryRevision?.values.at(0)?.unit?.symbol || "";
    });
  }
}

CustomFunctions.associate("PARAMETER.VALUE", parameterValue);
CustomFunctions.associate("PARAMETER.PARAMETER_TYPE_NAME", parameterparameterTypeName);
CustomFunctions.associate("PARAMETER.SOURCE_ID", parameterSourceId);
CustomFunctions.associate("PARAMETER.STATUS", parameterStatus);
CustomFunctions.associate("PARAMETER.UNIT_SYMBOL", parameterUnitSymbol);