/*global CustomFunctions */
import { getBatchers } from "../commands/commands";
import { Cache } from "./cache";
import { DdbEnvironment } from "./types";
import { DDB_ENVIRONMENT } from "./config";
import { chunk, union } from "./helpers";

interface Entry<T> {
  key: string;
  resolve: (result: T) => Promise<void>;
  reject: (error: CustomFunctions.Error) => void;
  state: "pending" | "fetching" | "fulfilled" | "rejected";
}

export interface RefreshableBatcher {
  refresh(): Promise<void>;
}

type Result<T> = { type: "success"; value: T } | { type: "error"; error: any };

export abstract class Batcher<T> implements RefreshableBatcher {
  #entries: Record<DdbEnvironment, Entry<T>[]>;
  batchSize: number;
  #timeouts: { [key in DdbEnvironment]?: ReturnType<typeof setTimeout> };
  #cache: Cache<T>;
  #resolves: (() => void)[];

  constructor(batchSize: number, cache: string | Cache<T>) {
    this.batchSize = batchSize;
    this.#entries = {
      develop: [],
      sandbox: [],
      production: [],
    };
    this.#timeouts = {};
    getBatchers().push(this);
    this.#cache = typeof cache === "string" ? new Cache(cache) : cache;
    this.#resolves = [];
  }

  enqueue<V>(
    key: string,
    invocation: CustomFunctions.StreamingInvocation<V>,
    valueSelector: (result: T) => V | Promise<V>
  ): void {
    invocation.onCanceled = () => this.cancel(key);
    const entries = this.#entries[DDB_ENVIRONMENT];

    const resolve = async (result: T) => invocation.setResult(await valueSelector(result));
    const reject = (error: CustomFunctions.Error) => invocation.setResult(error);

    entries.push({ key, resolve, reject, state: "pending" });
    const interval = this.#timeouts[DDB_ENVIRONMENT];
    if (entries.length >= this.batchSize) {
      clearTimeout(interval);
      delete this.#timeouts[DDB_ENVIRONMENT];
      this.#processBatch(false);
    } else if (interval === undefined) {
      this.#timeouts[DDB_ENVIRONMENT] = setTimeout(() => {
        this.#processBatch(false);
        delete this.#timeouts[DDB_ENVIRONMENT];
      }, 100);
    }
  }

  async #processBatch(sendAll: boolean) {
    const entries = this.#entries[DDB_ENVIRONMENT].filter((entry) => sendAll || entry.state === "pending");
    const toGet: Entry<T>[] = [];
    for (const entry of entries) {
      const cached = this.#cache.get(entry.key);
      if (cached !== undefined) {
        entry.resolve(cached);
        entry.state = "fulfilled";
      } else {
        toGet.push(entry);
        entry.state = "fetching";
      }
    }

    try {
      const resultMaps = await Promise.all(
        chunk([...new Set(toGet.map((entry) => entry.key))], this.batchSize).map((keys) => this.#sendRequest(keys))
      );

      const results = union(resultMaps);
      await Promise.all(toGet.map((entry) => this.#processEntry(results, entry)));
    } catch (error) {
      for (const entry of toGet) {
        entry.reject(error);
        entry.state = "rejected";
      }
    }
    try {
      const toChange = await this.#cache.sync();
      for (const { environment, key, value } of toChange) {
        const entry = this.#entries[environment].find((entry) => entry.key === key);
        if (entry) {
          await entry.resolve(value);
          entry.state = "fulfilled";
        }
      }
    } finally {
      if (
        Object.values(this.#entries).every((entries) =>
          entries.every((entry) => entry.state == "fulfilled" || entry.state == "rejected")
        )
      ) {
        for (const resolve of this.#resolves) {
          resolve();
        }
        this.#resolves = [];
      }
    }
  }

  async #processEntry(results: Map<string, Result<T>>, entry: Entry<T>) {
    const result = results.get(entry.key);
    if (result === undefined) {
      entry.reject(new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, `No result for key ${entry.key}`));
      entry.state = "rejected";
    } else if (result.type === "error") {
      entry.reject(new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, String(result.error)));
      entry.state = "rejected";
    } else {
      await entry.resolve(result.value);
      entry.state = "fulfilled";
      this.#cache.set(entry.key, result.value);
    }
  }

  async #sendRequest(keys: string[]): Promise<Map<string, Result<T>>> {
    try {
      const results = await this._getResults(keys);
      const wrapped = new Map([...results.entries()].map(([key, value]) => [key, { type: "success", value }] as const));
      return wrapped;
    } catch (error) {
      const half = Math.ceil(keys.length / 2);
      if (half <= 1) {
        const result = new Map<string, Result<T>>();
        result.set(keys[0], { type: "error", error });
        return result;
      }
      const [left, right] = [keys.slice(0, half), keys.slice(half)];
      const results = await Promise.all([this.#sendRequest(left), this.#sendRequest(right)]);
      return union(results);
    }
  }

  protected abstract _getResults(keys: string[]): Promise<Map<string, T>>;

  cancel(key: string) {
    this.#cache.delete(key);
    const entries = this.#entries[DDB_ENVIRONMENT];
    const index = entries.findIndex((entry) => entry.key === key);
    if (index !== -1) {
      const entry = entries[index];
      entry.reject(new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Cancelled"));
      entries.splice(index, 1);
    }
  }

  async refresh(): Promise<void> {
    this.#cache.clear();
    for (const environment of Object.keys(this.#entries) as DdbEnvironment[]) {
      const interval = this.#timeouts[environment];
      if (interval !== undefined) {
        clearTimeout(interval);
        delete this.#timeouts[environment];
      }
      if (this.#entries[environment].length > 0) {
        await this.#processBatch(true);
      }
    }
    await this.#cache.sync();
  }

  async waitToSettle(): Promise<void> {
    return new Promise((resolve) => {
      this.#resolves.push(resolve);
    });
  }
}
