/* global Excel */
import { DdbEnvironment } from "./types";
import { DDB_ENVIRONMENT } from "./config";

interface Entry<T> {
  value: T;
  updated: Date;
}

interface ChangedValue<T> {
  environment: DdbEnvironment;
  key: string;
  value: T;
}

export class Cache<T> {
  #entries: Record<DdbEnvironment, Record<string, Entry<T>>>;
  #cacheKey: string;

  constructor(cacheKey: string) {
    this.#entries = { develop: {}, sandbox: {}, production: {} };
    this.#cacheKey = cacheKey;
  }

  get(key: string): T | undefined {
    return this.#entries[DDB_ENVIRONMENT][key]?.value;
  }

  set(key: string, value: T): void {
    this.#entries[DDB_ENVIRONMENT][key] = { value, updated: new Date() };
  }

  delete(key: string): void {
    delete this.#entries[DDB_ENVIRONMENT][key];
  }

  clear(): void {
    for (const environment of Object.keys(this.#entries) as DdbEnvironment[]) {
      for (const key of Object.keys(this.#entries[environment])) {
        this.delete(key);
      }
    }
  }

  async sync(): Promise<ChangedValue<T>[]> {
    return await Excel.run(async (context) => {
      const entry = context.workbook.settings.getItemOrNullObject(this.#cacheKey);
      entry.load("value");
      await context.sync();
      let changedValues: ChangedValue<T>[] = [];
      if (entry.isNullObject) {
        context.workbook.settings.add(this.#cacheKey, JSON.stringify(this.#entries));
      } else {
        if (entry.value) {
          changedValues = this.#combine(
            JSON.parse(entry.value, (key, value) => (key === "updated" ? new Date(value) : value))
          );
        }
        entry.set({ value: JSON.stringify(this.#entries) });
      }
      await context.sync();
      return changedValues;
    });
  }

  #combine(newEntries: Record<DdbEnvironment, Record<string, Entry<T>>>): ChangedValue<T>[] {
    const changedValues: ChangedValue<T>[] = [];
    for (const [environment, entries] of Object.entries(newEntries) as [DdbEnvironment, Record<string, Entry<T>>][]) {
      for (const [key, newEntry] of Object.entries(entries)) {
        const currentEntry = this.#entries[environment][key];
        if (!currentEntry || newEntry.updated > currentEntry.updated) {
          changedValues.push({ environment, key, value: newEntry.value });
          this.#entries[environment][key] = newEntry;
        }
      }
    }
    return changedValues;
  }
}
