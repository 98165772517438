/*global CustomFunctions */
import { AssetSchema } from "@ddb/parameter-service";
import { toMap } from "../../shared/asyncIterator";
import { Batcher } from "../../shared/batch";
import { parameterService, unpaginate, validateEnvironment } from "../../shared/ddb";
import { DdbEnvironment } from "../../shared/types";
import { isGuid } from "../../shared/helpers";

class AssetBatcher extends Batcher<AssetSchema> {
  async _getResults(environment: DdbEnvironment, keys: string[]): Promise<Map<string, AssetSchema>> {
    const client = parameterService(environment);
    return await toMap(
      unpaginate(
        (after) => client.getAssets({ assetId: keys, after }),
        (data) => data.data.assets,
        (data) => data.data.paging?.cursors?.after
      ),
      (asset) => asset.id
    );
  }
}

const assetBatcher = new AssetBatcher(10, "assets");

/**
 * Gets DDB asset name.
 * @customfunction ASSET.NAME
 * @param environment The DDB environment to use.
 * @param assetId The asset ID.
 * @returns The asset name.
 * @streaming
 */
export function assetName(
  environment: string,
  assetId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(assetId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Asset ID must be a GUID.");
  }
  assetBatcher.enqueue(environment, assetId, invocation, (asset) => asset.name);
}

/**
 * Gets DDB asset parent.
 * @customfunction ASSET.PARENT_ID
 * @param environment The DDB environment to use.
 * @param assetId The asset ID.
 * @returns The asset name.
 * @streaming
 */
export function parentAssetId(
  environment: string,
  assetId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(assetId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Asset ID must be a GUID.");
  }
  assetBatcher.enqueue(environment, assetId, invocation, (asset) => asset.parent ?? "");
}

/**
 * Gets DDB asset children.
 * @customfunction ASSET.CHILD_IDS
 * @param environment The DDB environment to use.
 * @param assetId The asset ID.
 * @returns The asset child IDs.
 * @streaming
 */
export function childIds(
  environment: string,
  assetId: string,
  invocation: CustomFunctions.StreamingInvocation<string[][]>
): void {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(assetId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Asset ID must be a GUID.");
  }
  assetBatcher.enqueue(environment, assetId, invocation, (asset) => asset.children?.map((child) => [child]) ?? []);
}

/**
 * Gets DDB asset type ID.
 * @customfunction ASSET.ASSET_TYPE_ID
 * @param environment The DDB environment to use.
 * @param assetId The asset ID.
 * @returns The asset type ID.
 * @streaming
 */
export function assetTypeId(
  environment: string,
  assetId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(assetId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Asset ID must be a GUID.");
  }
  assetBatcher.enqueue(environment, assetId, invocation, (asset) => asset.asset_type.id);
}

/**
 * Gets DDB asset type name.
 * @customfunction ASSET.ASSET_TYPE_NAME
 * @param environment The DDB environment to use.
 * @param assetId The asset ID.
 * @returns The asset type name.
 * @streaming
 */
export function assetTypeName(
  environment: string,
  assetId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(assetId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Asset ID must be a GUID.");
  }
  assetBatcher.enqueue(environment, assetId, invocation, (asset) => asset.asset_type.name);
}

CustomFunctions.associate("ASSET.NAME", assetName);
CustomFunctions.associate("ASSET.PARENT_ID", parentAssetId);
CustomFunctions.associate("ASSET.CHILD_IDS", childIds);
CustomFunctions.associate("ASSET.ASSET_TYPE_ID", assetTypeId);
CustomFunctions.associate("ASSET.ASSET_TYPE_NAME", assetTypeName);