/*global CustomFunctions */
import { ProjectSchema } from "@ddb/environment-context-service";
import { toMap } from "../../shared/asyncIterator";
import { Batcher } from "../../shared/batch";
import { environmentContextService, unpaginate } from "../../shared/ddb";
import { isGuid, formulaErrorHandling } from "../../shared/helpers";
import { FormulaErrorFormat } from "../../shared/types";

class ProjectBatcher extends Batcher<ProjectSchema> {
  async _getResults(keys: string[]): Promise<Map<string, ProjectSchema>> {
    const client = environmentContextService();
    return await toMap(
      unpaginate(
        (after) => client.getProjects({ after, projectId: keys }),
        (data) => data.data.projects,
        (data) => data.data.paging?.cursors?.after
      ),
      (project) => project.project_id,
      (project) => project
    );
  }
}

const projectBatcher = new ProjectBatcher(10, "projects");

/**
 * Gets DDB project name.
 * @customfunction PROJECT.NAME
 * @param projectId The project ID.
 * @returns The project name.
 * @streaming
 */
export function projectName(projectId: string, invocation: CustomFunctions.StreamingInvocation<string>): void {
  if (!isGuid(projectId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PROJECT_ID));
    return;
  }
  projectBatcher.enqueue(projectId, invocation, (project) => project.short_title);
}

/**
 * Gets DDB project number.
 * @customfunction PROJECT.NUMBER
 * @param projectId The project ID.
 * @returns The project number.
 * @streaming
 */
export function projectNumber(projectId: string, invocation: CustomFunctions.StreamingInvocation<string>): void {
  if (!isGuid(projectId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PROJECT_ID));
    return;
  }
  projectBatcher.enqueue(projectId, invocation, (project) => project.number);
}

/**
 * Gets DDB project scope of works.
 * @customfunction PROJECT.SCOPE_OF_WORKS
 * @param projectId The project ID.
 * @returns The project scope of works.
 * @streaming
 */
export function projectScopeOfWorks(projectId: string, invocation: CustomFunctions.StreamingInvocation<string>): void {
  if (!isGuid(projectId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PROJECT_ID));
    return;
  }
  projectBatcher.enqueue(projectId, invocation, (project) => project.scope_of_works ?? "");
}

CustomFunctions.associate("PROJECT.NAME", projectName);
CustomFunctions.associate("PROJECT.NUMBER", projectNumber);
CustomFunctions.associate("PROJECT.SCOPE_OF_WORKS", projectScopeOfWorks);